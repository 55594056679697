import React from "react"
import { Link } from "gatsby"
import Modal from "react-modal"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import Flickity from "react-flickity-component"

// Redux
import { invertHeader, resetHeader } from "../../actions"
import { connect } from "react-redux"

import WorkHeader from "./WorkHeader"
import WorkRelated from "./WorkRelated"
import Lonform from "../longform/Longform"
import HtmlParser from "../../components/htmlParser"
import Sharer from "../../components/Sharer"
import EmbeddedPlayer from "../../components/EmbeddedPlayer"
import FigureBox, { boxFormats } from "../../components/FigureBox"
import { Player, ControlBar, VolumeMenuButton } from "video-react"
import { formatedDate } from "../../utils/date"
import PageAttributes from "../../utils/PageAttributes"
import Close from "../../svg/Close"
import Div100vh from "../../utils/Div100vh"

const FlickityOld =
  typeof window !== `undefined` ? require(`flickity`) : undefined



// init modal element
Modal.setAppElement(`#___gatsby`)

const textColumnClassName = `work__single__body entry col-12 col-lg-9 col-xl-6 mx-auto`

const flickityOptions = {
  initialIndex: 2,
  wrapAround: true,
  pageDots: false,
  draggable: true,
  contain: true,
}

class WorkSingleComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quickLookMode: false,
      modalIsClosing: false,
    }

    this.data = props.data.work
    this.related = props.data.relatedByBrand

    // holding modal slider
    this.slider = null

    // event for toggling modal with quickview mode
    this.toggleMode = this.toggleMode.bind(this)

    this.player = []
    this.setMuted = this.setMuted.bind(this)
  }

  componentDidMount() {
    if (this.props.data.work.fullscreenHeader == true) {
      this.props.invertHeader()
    }
  }

  componentWillUnmount() {
    this.props.resetHeader()
  }

  isFullscreenHeader() {
    return this.data.fullscreenHeader === true ? true : false
  }

  headerLayoutClassName() {
    return this.isFullscreenHeader() ? `is-fullscreen` : ``
  }

  sideTitle() {
    if (this.isFullscreenHeader()) {
      return <h1 className="work__sidetitle">{this.data.name}</h1>
    }
  }

  sideSubheadline() {
    if (this.isFullscreenHeader() && this.data.subheadline) {
      return <h2 className="h3">{this.data.subheadline}</h2>
    }
  }

  listCredits() {
    if (this.data.credits) {
      return this.data.credits.map((credit, index) => (
        <p className="work__credits__line" key={index}>
          <strong>{credit.label}</strong>
          {credit.content}
        </p>
      ))
    }
  }

  quickLookModeEnabled() {
    let total = 0
    if (this.data.longform) {
      this.data.longform.map(longform => {
        if (longform.images) {
          longform.images.map((image, index) => {
            total += 1
          })
        }
        if (longform.embeddedPlayers) {
          longform.embeddedPlayers.map((embed, index) => {
            total += 1
          })
        }
      })
    }
    // return true if 3 images or embededed were found
    return total >= 3 ? true : false
  }

  listMedias() {
    let slides = []

    this.data.longform.map(longform => {
      if (longform.images) {
        longform.images.map((image, index) => {
          if (image) {
            slides.push(
              <FigureBox
                source={image}
                format={boxFormats.auto}
                backgroundPosition={`center center`}
                backgroundSize="contain"
              />
            )
          }
        })
      }

      if (longform.embeddedPlayers) {
        longform.embeddedPlayers.map((embed, index) => {
          slides.push(
            <EmbeddedPlayer
              url={embed.emdeddedUrl}
              previewImage={embed.previewImage}
            />
          )
        })
      }
    })

    return slides.map((slide, index) => (
      <div key={index} className="work__single__quickview__slide">
        <div className="inner">{slide}</div>
      </div>
    ))
  }
  setMuted(index) {
    if (this.player[index].muted) {
      this.player.forEach(player => {
        player.muted = true
      })
    }
    this.player[index].muted
      ? (this.player[index].muted = false)
      : (this.player[index].muted = true)
  }

  initModal() {
    this.slider = new FlickityOld(this.refs.slider, {
      cellAlign: `left`,
      draggable: true,
      wrapAround: true,
      percentPosition: true,
      contain: false,
      pageDots: false,
      selectedAttraction: 0.085,
      friction: 0.65,
    })
  }

  toggleMode(e = null, modalIndex = 0) {
    if (e) e.preventDefault()
    this.setState({
      quickLookMode: !this.state.quickLookMode ? true : false,
      modalIsClosing: false,
      modalIndex: modalIndex,
    })
  }

  closeModal(e = null) {
    if (e) e.preventDefault()
    if (!this.state.modalIsClosing) {
      this.setState({
        modalIsClosing: true,
      })
      setTimeout(() => {
        this.toggleMode(e)
      }, 1000)
    }
  }

  modalClassName(base = `modal`) {
    let classes = [base]

    if (this.state.modalIsClosing) {
      classes.push(`is-closing`)
    }

    return classes.join(` `)
  }

  render() {
    let shareImage, tempShareImage, shareImageBeforeSplit
    if(this.data.mainImage.gatsbyImageData !== null){
     shareImageBeforeSplit = this.data.mainImage.gatsbyImageData.images.fallback ? this.data.mainImage.gatsbyImageData.images.fallback.src : this.data.mainImage.gatsbyImageData.images.sources[0].srcSet; 
     tempShareImage = shareImageBeforeSplit.split('?');
     shareImage = `https:`+ tempShareImage[0]+ encodeURI(`?w=1200&h=630&fm=jpg`)
    }
    else{
      shareImage = `https://images.ctfassets.net/ckso4uqg4vio/4EjQ8pCZaiM8nVxGSfjnkf/e96dfe5ca33a3d8c60b0cd200ca7a7b2/share-image-fallback.jpg`
    }
    // console.log(shareImage);
    return (
      <Layout>
        <SEO 
          title={this.data.name} 
          description={this.data.subheadline}
          seoFields={this.data.seoFields}
          image={
            this.data.mainImage ? shareImage : null
          } 
        />
      <section className={`work__single ${this.headerLayoutClassName()}`}>

        {this.quickLookModeEnabled() && (
          <Modal
            className={this.modalClassName(`modal__main`)}
            overlayClassName={this.modalClassName(`modal__overlay`)}
            onAfterOpen={e => this.initModal()}
            onRequestClose={e => this.closeModal(e)}
            isOpen={this.state.quickLookMode}
          >
            <Div100vh>
              <div className="modal__inner">
                <a
                  href
                  className="modal__close"
                  onClick={e => this.closeModal(e)}
                >
                  <Close />
                </a>
                <header className="modal__header is-text-center">
                  <h5 className="no-margin">{this.data.name}</h5>
                </header>
                <div className="modal__content">
                  <div ref="slider" className="work__single__quickview">
                    {this.listMedias()}
                  </div>
                </div>
              </div>
            </Div100vh>
          </Modal>
        )}

        <header className="single__header">
          <p className="h4 text-center">
            <Link to="/work/">Work</Link>
          </p>
        </header>

        {this.data.mainImage && (
          <WorkHeader
            work={this.props.data.work}
            top={this.isFullscreenHeader()}
            headerLayoutClassName={this.headerLayoutClassName()}
          />
        )}

        <header className="work__single__intro container-fluid">
          <div className="row">
            <aside className="work__credits col-12 col-md-6 mb-3">
              {this.sideTitle()}

              {this.data.brand && (
                <p className="work__credits__line">
                  <strong>Client</strong>
                  <Link to={`/work/brand/${this.data.brand.slug}`}>
                    {this.data.brand.brandName}
                  </Link>
                </p>
              )}

              {this.data.office && (
                <p className="work__credits__line">
                  <strong>Agency</strong>
                  <Link to={`/about/office/${this.data.office.slug}`}>
                    {this.data.office.officeName}
                  </Link>
                </p>
              )}

              {/* the rest of credits */}
              {/* {this.listCredits()} */}

              {this.data.launchedOn && (
                <p className="work__credits__line mt-md-3">
                  {formatedDate(this.data.launchedOn, `MMMM YYYY`)}
                </p>
              )}
            </aside>

            <aside className="work__single__short_description col-12 col-md-6">
              {this.quickLookModeEnabled() && (
                <nav className="work__single__toggle_view_mode mb-2">
                  <ul className="is-unstyled row">
                    <li className="col col-auto">
                      <a
                        href
                        className="is-active"
                        onClick={e => e.preventDefault()}
                      >
                        Full story
                      </a>
                    </li>
                    <li className="col col-auto">
                      <a href="javascript:;" className="" onClick={e => this.toggleMode(e)}>
                        Quick look mode
                      </a>
                    </li>
                  </ul>
                </nav>
              )}

              {this.data.shortDescription && (
                <div className="entry">
                  <div>{this.sideSubheadline()}</div>
                  <HtmlParser
                    key={new Date()}
                    html={this.data.shortDescription.childMarkdownRemark.html}
                  />
                </div>
              )}
            </aside>
          </div>
        </header>

        <Lonform
          rows={this.data.longform}
          textColumnClassName={textColumnClassName}
          modalToggle={this.toggleMode}
          isCaseStudy={this.data.caseStudiesWorkDesign}
        />

        {/* {this.data.longform &&
          this.data.longform.map(longform => {
            if (longform.inlineVideos && longform.instaStories) {
              console.log
              return (
                <aside key={longform.id} className="pb-6">
                  <Flickity
                    className={`work__single__instagram`}
                    elementType={`div`}
                    reloadOnUpdate
                    static
                    options={flickityOptions}
                  >
                    {longform.inlineVideos.map((inlineVideo, index) => (
                      <div
                        key={index}
                        className="work__single__instagram__slide"
                      >
                        {inlineVideo.file && (
                          <Player
                            ref={player => {
                              this.player[index] = player
                            }}
                            playsInline
                            src={inlineVideo.file.url}
                            muted={true}
                            autoPlay={true}
                            loop={true}
                            controls={false}
                            fluid={true}
                          >
                            <ControlBar
                              autoHide={false}
                              disableDefaultControls={true}
                            >
                              <VolumeMenuButton />
                            </ControlBar>
                            <div
                              className={`toggleButton`}
                              onClick={() => this.setMuted(index)}
                            />
                          </Player>
                        )}
                      </div>
                    ))}
                  </Flickity>
                </aside>
              )
            }
          })} */}

        {this.data.name && <Sharer title={this.data.name} />}

        {this.related &&
          this.related.work &&
          this.related.work.length > 1 && (
            <WorkRelated
              allWork={this.related.work}
              slugToExclude={this.data.slug}
              max={2}
            />
          )}
      </section>
      </Layout>
    )
  }
}

const mapStateToProps = store => {
  return {
    SiteHeaderState: store.SiteHeaderState,
  }
}

export default connect(
  mapStateToProps,
  {
    invertHeader,
    resetHeader,
  }
)(WorkSingleComponent)