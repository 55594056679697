import React from "react"
import { withBreakpoints } from "react-breakpoints"
import Reveal from "react-reveal/Reveal"
import FigureBox, { boxFormats } from "../../components/FigureBox"
import InlinePlayer from "../../components/InlinePlayer"

class WorkHeader extends React.Component {
  constructor(props) {
    super(props)
    this.data = props.work
  }

  header() {
    return (
      <Reveal
        effect="effects__fade_up"
        delay={750}
        duration={1000}
        distance="10%"
      >
        <header
          className={`work__single__header row ${
            this.props.headerLayoutClassName
          }`}
        >
          <h1 className="work__title col-11 col-lg-9 col-xl-7 mx-auto text-center">
            {this.data.name}
          </h1>
          <hgroup className="work__meta col-12 text-center">
            {this.data.categories && <span>{this.data.categories.name}</span>}
            {this.data.office != null && (
              <span className="office__shortname">
                {this.data.office.officeName}
              </span>
            )}
          </hgroup>
          {this.data.subheadline && (
            <h2 className="h3 work__subheadline col-12 col-lg-9 col-xl-7 pl-4 pr-4 mx-auto text-center">
              {this.data.subheadline}
            </h2>
          )}
        </header>
      </Reveal>
    )
  }

  figureContent() {
    if (this.data.videoPreview && !this.data.videoFull) {
      return (
        <InlinePlayer
          src={this.data.videoPreview.file.url}
          resume={true}
          hideNavOnPlay={this.data.fullscreenHeader ? true : false}
          poster={this.data.mainImage}
        />
      )
    } else if (
      this.data.videoFull &&
      this.data.videoFull.file &&
      !this.data.differPreviewVideo
    ) {
      return (
        <InlinePlayer
          src={this.data.videoFull.file.url}
          resume={true}
          hideNavOnPlay={this.data.fullscreenHeader ? true : false}
          poster={this.data.mainImage}
        />
      )
    } else if (
      this.data.differPreviewVideo &&
      this.data.videoFull.file &&
      this.data.videoPreview
    ) {
      return (
        <InlinePlayer
          srcFull={this.data.videoFull.file.url}
          src={this.data.videoPreview.file.url}
          resume={true}
          hideNavOnPlay={this.data.fullscreenHeader ? true : false}
          poster={this.data.mainImage}
        />
      )
    } else {
      return (
        <FigureBox
          source={this.data.mainImage}
          alt={this.data.name}
          type={this.props.top ? `background` : `inline`}
          format={this.props.top ? boxFormats.fullscreen : boxFormats.auto}
        />
      )
    }
  }

  figure() {
    return (
      <Reveal
        fade
        top
        distance={`10%`}
        duration={2500}
        delay={this.props.delay}
      >
        <figure
          className={`work__single__main_image ${
            this.props.headerLayoutClassName
          }`}
        >
          {this.figureContent()}
        </figure>
      </Reveal>
    )
  }

  render() {
    return (
      <div>
        {this.header()}
        {this.figure()}
      </div>
    )
  }
}

export default withBreakpoints(WorkHeader)
