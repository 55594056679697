import React from "react"
import { graphql } from 'gatsby'

import WorkSingleComponent from "./WorkSingleComponent"

class WorkSingle extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <WorkSingleComponent data={this.props.data} />
  }
}

export default WorkSingle

//
// Each work fields
//
export const query = graphql`
  query WorkQuery($slug: String!, $brandSlug: String!) {
    work: contentfulWork(slug: { eq: $slug }) {
      ...workFragment
    }
    relatedByBrand: contentfulClients(slug: { eq: $brandSlug }) {
      work {
        ...workFragmentBase
      }
    }
  }
`
